<template>
  <div>
    <div class="mb-4 flex items-center">
      <h2 class="mr-3">
        <router-link to="/news">News</router-link>
      </h2>

      <template v-if="!error">
        <button
          type="button"
          class="font-semibold rounded-xl px-2.5 py-0.5 flex items-center gap-0.5"
          :class="{ 'bg-gray-100': activeTab === 'rising' }"
          @click="selectTab('rising')">
          Rising

          <InlineSvg
            class="h-4 w-4 text-yellow-400"
            :src="require('@/assets/fontawesome/sharp-solid/bolt.svg')"/>
        </button>

        <button
          type="button"
          class="font-semibold rounded-xl px-2.5 py-0.5 flex items-center gap-0.5"
          :class="{ 'bg-gray-100': activeTab === 'hot' }"
          @click="selectTab('hot')">
          Hot

          <InlineSvg
            class="h-4 w-4 text-red-400"
            :src="require('@/assets/fontawesome/sharp-solid/fire-flame-curved.svg')"/>
        </button>

        <span
          v-show="loading"
          class="!border-2 !border-b-gray-500 w-4 h-4 loader ml-2"/>
      </template>
    </div>

    <DashboardAlertMessage
      v-if="error || !items.length"
      :type="error ? 'error' : 'neutral'"
      :heading="error ? error : 'No data'"/>

    <div
      v-else
      class="divide-y divide-gray-100 border border-gray-100 rounded-lg p-4 relative">
      <section
        v-for="item in items"
        :key="item.id"
        class="py-2">
        <h3 class="font-normal text-sm mb-1">
          <a
            class="hover:text-blue-400"
            target="_blank"
            :href="item.url">{{ item.title }}</a>
        </h3>

        <div class="flex items-center gap-2">
          <a
            target="_blank"
            class="text-xs text-slate-500"
            :href="`https://${item.domain}`">
            {{ item.source.title }}
          </a>

          <div
            v-if="item.currencies"
            class="flex items-center gap-1.5">
            <router-link
              v-for="(currency, currencyIndex) in item.currencies"
              :key="currencyIndex + currency.code"
              :to="currency.code">
              <SocialActivityCoinLogo
                class="w-5 h-5"
                :alt-text="`${currency.title} (${currency.code})`"
                :ticker="currency.code"/>
            </router-link>
          </div>

          <span class="text-xs text-slate-500">
            {{ formatDateUTC(item.created_at) }}
          </span>
        </div>
      </section>
    </div>

    <router-link
      class="mt-2 flex items-center hover:text-blue-400"
      to="/news">
      Show all

      <InlineSvg
        class="h-3 w-2.5 ml-1"
        :src="require('@/assets/fontawesome/sharp-solid/chevron-right.svg')"/>
    </router-link>
  </div>
</template>

<script>
import SocialActivityCoinLogo from '@/components/SocialActivity/Includes/SocialActivityCoinLogo.vue'
import DashboardAlertMessage from '@/components/Dashboard/includes/DashboardAlertMessage.vue'
import formatDate from '@/mixins/formatDate'
import { get } from '@/helpers/fetch'

export default {
  name: 'DashboardNews',
  components: {
    SocialActivityCoinLogo,
    DashboardAlertMessage
  },
  mixins: [formatDate],
  props: {
    data: {
      type: Object,
      default: () => ({
        value: {
          items: {}
        },
        error: null
      }),
      required: true
    },
    limit: {
      type: Number,
      default: 6
    }
  },
  emits: ['update:data'],
  data () {
    return {
      activeTab: 'rising',
      loading: false
    }
  },
  computed: {
    error () {
      return this.data.error
    },
    items () {
      return this.data.value.items || []
    }
  },
  watch: {},
  mounted () {},
  methods: {
    selectTab (tabName) {
      if (this.loading) {
        return
      }

      this.activeTab = tabName
      this.updateNews(tabName)
    },
    async updateNews (filter) {
      if (this.loading) {
        return
      }

      this.loading = true

      const { items } = await get('/dashboard/news', { limit: this.limit, filter: filter })

      const data = structuredClone(this.data)
      data.value.items = items

      this.$emit('update:data', data)
      this.loading = false
    }
  }
}
</script>
