<template>
  <div
    v-if="isDev && debugOpen"
    class="debug fixed z-10 top-4 right-0 bg-slate-900 text-white z-10 bg-opacity-70">
    <div
      class="absolute -top-4 right-0 bg-slate-900 bg-opacity-70 h-4 w-4 flex items-center justify-center cursor-pointer"
      @click="debugOpen = false">
      <InlineSvg
        class="w-3"
        :src="require('@/assets/fontawesome/sharp-solid/xmark-large.svg')"/>
    </div>

    <div class="px-1">
      <pre><small><strong>rights:</strong> {{ rights }}</small></pre>
    </div>
  </div>
</template>

<script>
import { mapState, mapWritableState } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import { useAppStore } from '@/stores/app'

export default {
  name: 'DebugWindow',
  components: {},
  data () {
    return {}
  },
  computed: {
    isDev () {
      return process.env.NODE_ENV === 'development'
    },
    ...mapState(useAuthStore, [
      'rights',
      'pathToRedirectAfterLogin'
    ]),
    ...mapWritableState(useAppStore, [
      'debugOpen'
    ])
  },
  mounted () {
  },
  methods: {}
}
</script>

