<template>
  <div class="max-w-xs w-full mx-auto p-4">
    <form @submit.prevent="login">
      <div class="mb-2">
        <label
          for="username_login_input"
          class="text-xs leading-4 text-slate-800">
          Username
        </label>

        <div class="flex items-center">
          <input
            id="username_login_input"
            v-model="username"
            type="text"
            class="w-full text-lg text-slate-800 placeholder-gray-400 px-1.5 py-0.5 bg-white rounded border-gray-300 shadow-none focus:border-slate-300 focus:ring-2 focus:ring-slate-300 focus:ring-opacity-50">
        </div>
      </div>

      <div>
        <label
          for="password_login_input"
          class="text-xs leading-4 text-slate-800">
          Password
        </label>

        <div class="flex items-center">
          <input
            id="password_login_input"
            v-model="password"
            type="password"
            class="w-full text-lg text-slate-800 placeholder-gray-400 px-1.5 py-0.5 bg-white rounded border-gray-300 shadow-none focus:border-slate-300 focus:ring-2 focus:ring-slate-300 focus:ring-opacity-50">
        </div>
      </div>

      <AlertMessage
        v-if="errorMessage"
        class="mt-4"
        type="error"
        :heading="errorMessage"/>

      <button
        class="mt-4 block text-lg text-slate-50 px-10 py-1 bg-slate-600 rounded shadow-none focus:outline-none focus:ring-2 focus:ring-slate-300"
        type="submit">
        Sign In
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import AlertMessage from '@/components/Functional/AlertMessage.vue'

export default {
  name: 'LoginForm',
  components: {
    AlertMessage
  },
  data () {
    return {
      username: '',
      password: '',
      errorMessage: null
    }
  },
  computed: {},
  mounted () {},
  methods: {
    ...mapActions(useAuthStore, [
      'authenticate'
    ]),
    login () {
      if (this.password && this.username) {
        const hostname = window.location.hostname

        if (['cryptoat.org', 'localhost'].includes(hostname)) {
          this.authenticateUser()
        } else {
          if (['fragilehill', 'nickdolan', 'user'].includes(this.username)) {
            this.authenticateUser()
          } else {
            this.errorMessage = 'Not allowed'
          }
        }
      }
    },
    async authenticateUser () {
      try {
        await this.authenticate({
          password: this.password,
          username: this.username
        })
      } catch (error) {
        if (error?.message) {
          this.errorMessage = error.message
        }
      }
    }
  }
}
</script>

