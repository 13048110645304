<template>
  <form @submit.prevent="submit">
    <span class="text-xs leading-4 text-slate-800">{{ name }}</span>

    <div class="flex items-center">
      <input
        :id="`filter_min_${id}`"
        v-model="from"
        type="number"
        name="md_from"
        :step="step"
        class="block text-sm text-slate-800 placeholder-gray-400 px-1.5 py-0.5 max-w-[120px] bg-white rounded border-gray-300 shadow-none focus:border-blue-300 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50"
        :placeholder="rangeMinMax.from"
        @input="debounceSearch">

      <span class="mx-1.5 text-slate-800">to</span>

      <input
        :id="`filter_max_${id}`"
        v-model="to"
        type="number"
        name="md_from"
        :step="step"
        class="block text-sm text-slate-800 placeholder-gray-400 px-1.5 py-0.5 max-w-[120px] bg-white rounded border-gray-300 shadow-none focus:border-blue-300 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50"
        :placeholder="rangeMinMax.to"
        @input="debounceSearch">
    </div>
  </form>
</template>

<script>
import { uid } from 'radash'

export default {
  name: 'FromToFilter',
  components: {},
  props: {
    name: {
      type: String,
      default: 'Name'
    },
    step: {
      type: Number,
      default: 0.1
    },
    range: {
      type: Object,
      default: () => ({
        from: null,
        to: null
      })
    },
    rangeMinMax: {
      type: Object,
      default: () => ({
        from: null,
        to: null
      })
    }
  },
  emits: ['submit'],
  data () {
    return {
      id: uid(5),
      from: null,
      to: null,
      typing: false,
      debounce: null
    }
  },
  computed: {},
  mounted () {
    if (this.range.from || this.range.to) {
      this.from = this.range.from
      this.to = this.range.to
    }
  },
  methods: {
    submit () {
      this.$emit('submit', {
        from: this.from,
        to: this.to
      })
    },
    debounceSearch () {
      this.typing = true

      if (this.debounce) clearTimeout(this.debounce)

      this.debounce = setTimeout(() => {
        this.typing = false
        this.submit()
      }, 500)
    }
  }
}
</script>

