<template>
  <div class="my-2 flex gap-4 items-center">
    <DateRangeInput
      :name="'Date range (UTC)'"
      :date-range="{ from: filters_current_values.start_date, to: filters_current_values.finish_date }"
      @submit="applyDateRange"/>

    <SelectInput
      v-model:selected="timeframe"
      class="w-40"
      label="Timeframe"
      :options="timeframes"/>
  </div>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useCorrelationsStore } from '@/stores/correlations'
import DateRangeInput from '@/components/Filters/DateRangeFilter.vue'
import SelectInput from '@/components/Inputs/SelectInput.vue'

export default {
  name: 'CorrelationsFilters',
  components: {
    DateRangeInput,
    SelectInput
  },
  props: {},
  data () {
    return {
      timeframes: [
        {
          title: '15m',
          value: '15m'
        },
        {
          title: '30m',
          value: '30m'
        },
        {
          title: '1h',
          value: '1h'
        }
      ]
    }
  },
  computed: {
    ...mapState(useCorrelationsStore, [
      'metadata',
      'filters_current_values'
    ]),
    ...mapWritableState(useCorrelationsStore, [
      'filters',
      'timeframe'
    ])
  },
  watch: {
    timeframe (value, oldValue) {
      if (value && value !== oldValue) {
        this.getCorrelations()
      }
    }
  },
  mounted () {},
  methods: {
    ...mapActions(useCorrelationsStore, [
      'getCorrelations'
    ]),
    applyDateRange (dateRange) {
      this.filters.daterange = dateRange

      this.getCorrelations()
    }
  }
}
</script>

