import { useAuthStore } from '@/stores/auth'
import { watch } from 'vue'
import { io } from 'socket.io-client'
import socketActions from '@/plugins/socketActions'

export default {
  install: () => {
    const authStore = useAuthStore()
    let socket = null

    // Watch token and open or close socket connection
    watch(() => authStore.token, (value, oldValue) => {
      // Connect
      if (value) {
        socket = io('api.cryptoat.org', {
          extraHeaders: {
            'x-access-token': authStore.token
          }
        })

        socket.on('connect', () => {
          authStore.isSocketConnected = true
        })

        // Handle socket message events
        socket.on('message', (message) => {
          const event = message.method
          const data = message.data

          const actions = socketActions().get(event)

          console.log('SOCKET EVENT:', event)

          if (actions !== undefined) {
            actions.forEach(action => {
              action(data)
            })
          }
        })
      }
      // Disconnect
      else if (oldValue && !value) {
        if (socket) {
          socket.disconnect()
          socket = null
          authStore.isSocketConnected = false
        }
      }
    }, {
      immediate: true
    })
  }
}
