<template>
  <div class="lg:ml-16">
    <MainSidebar v-if="token"/>

    <div class="flex-grow">
      <router-view/>
    </div>

    <DebugWindow/>
  </div>
</template>

<script>

import { useAuthStore } from '@/stores/auth'
import { mapState } from 'pinia'
import MainSidebar from '@/components/MainSidebar.vue'
import DebugWindow from '@/components/DebugWindow.vue'

export default {
  components: {
    MainSidebar,
    DebugWindow
  },
  computed: {
    ...mapState(useAuthStore, [
      'token',
      'userSettings'
    ])
  },
  beforeMount () {
    const packageVersion = localStorage.getItem('packageVersion')

    if (packageVersion !== process.env.PACKAGE_VERSION) {
      localStorage.setItem('packageVersion', process.env.PACKAGE_VERSION)
    }
  },
  mounted () {}
}
</script>
