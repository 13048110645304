<template>
  <form @submit.prevent="submit">
    <label
      :for="`filter_search_${id}`"
      class="text-xs leading-4 text-slate-800">{{ name }}</label>

    <div class="flex items-center relative">
      <input
        :id="`filter_search_${id}`"
        v-model="message"
        type="text"
        :name="`search_${id}`"
        class="block text-sm text-slate-800 placeholder-gray-400 px-1.5 py-0.5 max-w-[180px] bg-white rounded border-gray-300 shadow-none focus:border-slate-300 focus:ring-2 focus:ring-slate-300 focus:ring-opacity-50"
        :placeholder="placeholder"
        @input="debounceSearch">

      <div class="absolute top-0 bottom-0 right-1 flex items-center">
        <InlineSvg
          v-show="message"
          class="inline-block w-4 h-4 text-gray-400 cursor-pointer"
          :src="require('@/assets/svg/xmark.svg')"
          @click="clear"/>
      </div>
    </div>
  </form>
</template>

<script>
import { uid } from 'radash'

export default {
  name: 'SearchFilter',
  components: {},
  props: {
    name: {
      type: String,
      default: 'Name'
    },
    placeholder: {
      type: String,
      default: 'Type text'
    },
    timeout: {
      type: Number,
      default: 500
    },
    initialValue: {
      type: String,
      default: ''
    }
  },
  emits: ['update:search', 'search'],
  data () {
    return {
      id: uid(6),
      message: null,
      typing: null,
      debounce: null
    }
  },
  computed: {},
  mounted () {
    this.message = this.initialValue
  },
  methods: {
    clear () {
      this.message = ''
      this.submit()
    },
    submit () {
      this.$emit('update:search', this.message)
      this.$emit('search', this.message)
    },
    debounceSearch (event) {
      this.typing = true

      if (this.debounce) clearTimeout(this.debounce)

      this.debounce = setTimeout(() => {
        this.typing = false
        this.$emit('update:search', event.target.value)
        this.$emit('search', this.message)
      }, this.timeout)
    }
  }
}
</script>

