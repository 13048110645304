<template>
  <div
    class="vl-tooltip"
    :class="`vl-tooltip-${placement} vl-tooltip-align-${align}`">
    <i/>

    <div class="vl-tooltip-wrap">
      <div class="vl-tooltip-content">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placement: {
      type: String,
      default: 'auto-y'
    },
    align: {
      type: String,
      default: 'center' // start | center | end
    }
  }
}
</script>

<style>
  :root {
    --vl-tooltip-bg: rgba(51, 51, 51, 0.9);
    --vl-tooltip-max-width: 250px;
    --vl-tooltip-arrow-width: 4px;
    --vl-tooltip-arrow-height: 5px
  }

  .vl-tooltip {
    color: #fff;
    font-size: 14px;
    line-height: 1.4;
    position: absolute;
    z-index: 100
  }

  .vl-tooltip > i {
    color: var(--vl-tooltip-bg);
  }

  .vl-tooltip > .vl-tooltip-wrap, .vl-tooltip > i {
    position: absolute;
  }

  .vl-tooltip-wrap {
    width: var(--vl-tooltip-max-width);
  }

  .vl-tooltip-content {
    background: var(--vl-tooltip-bg);
    border-radius: 6px;
    display: inline-block;
    padding: 5px 6px;
    text-align: left;
  }

  .vl-tooltip-bottom > i, .vl-tooltip-top > i {
    border-left: var(--vl-tooltip-arrow-width) solid transparent;
    border-right: var(--vl-tooltip-arrow-width) solid transparent;
    margin-left: calc(var(--vl-tooltip-arrow-width) * -1);
  }

  .vl-tooltip-bottom.vl-tooltip-align-center > .vl-tooltip-wrap, .vl-tooltip-top.vl-tooltip-align-center > .vl-tooltip-wrap {
    margin-left: calc(var(--vl-tooltip-max-width) / -2);
    text-align: center
  }

  .vl-tooltip-bottom.vl-tooltip-align-start > .vl-tooltip-wrap, .vl-tooltip-top.vl-tooltip-align-start > .vl-tooltip-wrap {
    margin-left: -10px;
    text-align: left
  }

  .vl-tooltip-bottom.vl-tooltip-align-end > .vl-tooltip-wrap, .vl-tooltip-top.vl-tooltip-align-end > .vl-tooltip-wrap {
    margin-right: -10px;
    right: 0;
    text-align: right
  }

  .vl-tooltip-left > i, .vl-tooltip-right > i {
    border-bottom: var(--vl-tooltip-arrow-width) solid transparent;
    border-top: var(--vl-tooltip-arrow-width) solid transparent;
    margin-top: calc(var(--vl-tooltip-arrow-width) * -1)
  }

  .vl-tooltip-top > i {
    border-top: var(--vl-tooltip-arrow-height) solid;
    top: 0
  }

  .vl-tooltip-top > .vl-tooltip-wrap {
    bottom: 0
  }

  .vl-tooltip-bottom > i {
    border-bottom: var(--vl-tooltip-arrow-height) solid;
    bottom: 0
  }

  .vl-tooltip-bottom > .vl-tooltip-wrap {
    top: 0
  }

  .vl-tooltip-left > i {
    border-left: var(--vl-tooltip-arrow-height) solid;
    left: 0
  }

  .vl-tooltip-left > .vl-tooltip-wrap {
    right: 0;
    text-align: right;
    transform: translateY(-50%)
  }

  .vl-tooltip-right > i {
    border-right: var(--vl-tooltip-arrow-height) solid;
    right: 0
  }

  .vl-tooltip-right > .vl-tooltip-wrap {
    left: 0;
    transform: translateY(-50%)
  }
</style>
