<template>
  <div class="flex flex-col">
    <div class="overflow-x-auto mb-6">
      <div class="inline-block min-w-full py-2 align-middle">
        <div class="overflow-hidden">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th class="bg-[#f6f8fa]"/>

                <th
                  v-for="(item, index) in metadata.pairs"
                  :key="index"
                  class="bg-[#f6f8fa]">
                  {{ item }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(pair, index) in metadata.pairs"
                :key="index">
                <th class="bg-[#f6f8fa]">
                  {{ pair }}
                </th>

                <td
                  v-for="(corr, i) in grid[index]"
                  :key="i"
                  :style="{ backgroundColor: corr > 0 ? `rgba(76, 180, 170, ${corr})` : `rgba(187, 68, 48, ${Math.abs(corr)})` }"
                  class="cursor-pointer">
                  <div style="width: 100%">
                    {{ corr }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { usePriceChangesStore } from '@/stores/price-changes'

export default {
  name: 'PriceChangesTable',
  components: {},
  data () {
    return {
      coinsToDraw: []
    }
  },
  computed: {
    ...mapState(usePriceChangesStore, [
      'metadata',
      'grid'
    ])
  },
  created () {},
  mounted () {},
  methods: {}
}
</script>

<style scoped>
  tr:nth-child(2n) {
    background: initial;
  }

  td, th {
    border: 1px solid white;
    white-space: nowrap;
    @apply py-2 px-2
  }
</style>
