// import { useAuthStore } from '@/stores/auth'

export default function () {
  // const authStore = useAuthStore()

  // Prepare needed actions for each socket event
  return new Map([
    ['user.settings', [
      // authStore.userSettingsSocket,
    ]]
  ])
}


