<template>
  <button
    type="button"
    class="p-2 mt-2 lg:hidden w-16 flex items-center justify-center"
    @click.stop="sidebarIsOpen = !sidebarIsOpen">
    <InlineSvg
      class="w-6 h-6 text-gray-500"
      :src="require('@/assets/fontawesome/solid/bars.svg')"/>
  </button>

  <aside
    v-click-outside="closeSidebar"
    :class="[sidebarIsOpen ? 'w-60 min-w-[15rem]' : 'w-min']"
    class="sidebar fixed z-10 top-0 left-0 h-screen border-r border-gray-100"
    aria-label="Sidebar">
    <div
      :class="[sidebarIsOpen ? 'block' : 'hidden lg:flex']"
      class="py-4 px-3 h-full bg-gray-50 overflow-y-auto flex flex-col justify-between">
      <div class="flex-grow">
        <div class="-mt-2">
          <button
            type="button"
            class="mb-2 p-2"
            @click="sidebarIsOpen = !sidebarIsOpen">
            <InlineSvg
              v-show="sidebarIsOpen"
              class="w-6 h-6 text-gray-500 hidden lg:inline-block"
              :src="require('@/assets/fontawesome/solid/arrow-left-to-line.svg')"/>

            <InlineSvg
              v-show="sidebarIsOpen"
              class="w-6 h-6 text-gray-500 lg:hidden"
              :src="require('@/assets/fontawesome/solid/xmark-large.svg')"/>

            <InlineSvg
              v-show="!sidebarIsOpen"
              class="w-6 h-6 text-gray-500"
              :src="require('@/assets/fontawesome/solid/bars.svg')"/>
          </button>
        </div>


        <ul class="space-y-2">
          <li>
            <router-link
              title="Dashboard"
              to="/"
              exact-active-class="bg-gray-100"
              class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100"
              @click="closeSidebar">
              <InlineSvg
                class="flex-shrink-0 w-6 h-6 p-0.5 text-gray-500"
                :src="require('@/assets/fontawesome/regular/house.svg')"/>

              <span
                v-show="sidebarIsOpen"
                class="ml-3 whitespace-nowrap">Dashboard</span>
            </router-link>
          </li>

          <li v-if="username === 'fragilehill'">
            <router-link
              title="Dev Dashboard"
              to="/dev-dashboard"
              exact-active-class="bg-gray-100"
              class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100"
              @click="closeSidebar">
              <div class="relative">
                <InlineSvg
                  class="flex-shrink-0 w-6 h-6 p-0.5 text-gray-500"
                  :src="require('@/assets/fontawesome/regular/house.svg')"/>

                <span class="absolute -top-3 -right-1 text-[6px]">DEV</span>
              </div>


              <span
                v-show="sidebarIsOpen"
                class="ml-3 whitespace-nowrap">Dev Dashboard</span>
            </router-link>
          </li>

          <li>
            <router-link
              title="Social Activity"
              to="/social-activity"
              exact-active-class="bg-gray-100"
              class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100"
              @click="closeSidebar">
              <InlineSvg
                class="flex-shrink-0 w-6 h-6 p-0.5 text-gray-500"
                :src="require('@/assets/fontawesome/regular/table-list.svg')"/>

              <span
                v-show="sidebarIsOpen"
                class="ml-3 whitespace-nowrap">Social Activity</span>
            </router-link>
          </li>

          <li>
            <router-link
              title="Correlations"
              to="/correlations"
              exact-active-class="bg-gray-100"
              class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100"
              @click="closeSidebar">
              <InlineSvg
                class="flex-shrink-0 w-6 h-6 p-0.5 text-gray-500"
                :src="require('@/assets/fontawesome/regular/chart-scatter.svg')"/>

              <span
                v-show="sidebarIsOpen"
                class="ml-3 whitespace-nowrap">Correlations</span>
            </router-link>
          </li>

          <li>
            <router-link
              title="Tops"
              to="/tops"
              exact-active-class="bg-gray-100"
              class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100"
              @click="closeSidebar">
              <InlineSvg
                class="flex-shrink-0 w-6 h-6 p-0.5 text-gray-500"
                :src="require('@/assets/fontawesome/regular/arrow-up-right-dots.svg')"/>

              <span
                v-show="sidebarIsOpen"
                class="ml-3 whitespace-nowrap">Tops</span>
            </router-link>
          </li>

          <li>
            <router-link
              title="News"
              to="/news"
              exact-active-class="bg-gray-100"
              class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100"
              @click="closeSidebar">
              <InlineSvg
                class="flex-shrink-0 w-6 h-6 p-0.5 text-gray-500"
                :src="require('@/assets/fontawesome/regular/newspaper.svg')"/>

              <span
                v-show="sidebarIsOpen"
                class="ml-3 whitespace-nowrap">News</span>
            </router-link>
          </li>

          <li v-if="rights === 'admin' && 0">
            <router-link
              title="Price Changes"
              to="/price-changes"
              exact-active-class="bg-gray-100"
              class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100"
              @click="closeSidebar">
              <InlineSvg
                class="flex-shrink-0 w-6 h-6 p-0.5 text-gray-500"
                :src="require('@/assets/fontawesome/sharp-solid/table-cells.svg')"/>

              <span
                v-show="sidebarIsOpen"
                class="ml-3 whitespace-nowrap">Price Changes</span>
            </router-link>
          </li>

          <li v-if="rights === 'admin'">
            <router-link
              title="Users"
              to="/users"
              exact-active-class="bg-gray-100"
              class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100"
              @click="closeSidebar">
              <InlineSvg
                class="flex-shrink-0 w-6 h-6 p-0.5 text-gray-500"
                :src="require('@/assets/fontawesome/regular/users.svg')"/>

              <span
                v-show="sidebarIsOpen"
                class="ml-3 whitespace-nowrap">Users</span>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="flex flex-col">
        <div
          v-show="sidebarIsOpen"
          class="p-4 my-2 bg-gray-100 rounded-lg">
          <CurrentTime class="!text-blue-900"/>
        </div>

        <button
          title="Logout"
          type="button"
          class="w-full flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100"
          @click="logout();closeSidebar()">
          <InlineSvg
            class="flex-shrink-0 w-6 h-6 p-0.5 text-gray-500"
            :src="require('@/assets/fontawesome/regular/right-from-bracket.svg')"/>

          <span
            v-show="sidebarIsOpen"
            class="ml-3 whitespace-nowrap">Logout</span>
        </button>

        <router-link
          v-if="0"
          to="/account"
          exact-active-class=""
          class="flex items-center">
          <div class="h-10 w-10 bg-gray-200 rounded-full"/>

          <div v-show="sidebarIsOpen" class="ml-2">
            <p class="leading-4 text-xs text-gray-400">Account</p>
            <p class="leading-4 text-gray-500">@nickdolan</p>
          </div>
        </router-link>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useAppStore } from '@/stores/app'
import CurrentTime from '@/components/CurrentTime.vue'
import { useAuthStore } from '@/stores/auth'
import { clickOutside } from '@/directives/click-outside'

export default {
  name: 'MainSidebar',
  directives: {
    clickOutside
  },
  components: {
    CurrentTime
  },
  data () {
    return {}
  },
  computed: {
    ...mapWritableState(useAppStore, [
      'sidebarIsOpen'
    ]),
    ...mapState(useAuthStore, [
      'rights',
      'username'
    ])
  },
  mounted () {
  },
  methods: {
    ...mapActions(useAuthStore, [
      'logout'
    ]),
    closeSidebar () {
      this.sidebarIsOpen = false
    }
  }
}
</script>

<style>
  /* Avoid Chrome to see Safari hack */
  @supports (-webkit-touch-callout: none) {
    .sidebar {
      height: -webkit-fill-available !important;
    }
  }
</style>

