<template>
  <thead>
    <tr>
      <th
        scope="col"
        class="text-base font-bold border-r border-gray-100 text-center">
        <span class="p-3 leading-5">#</span>
      </th>

      <th
        scope="col"
        class="text-left text-base font-bold whitespace-nowrap border-r border-gray-100">
        <div
          class="p-3 flex items-center justify-between select-none leading-5 cursor-pointer"
          @click="toggleSortDirection('ticker')">
          Ticker

          <SocialActivitySortIcon
            :field="'ticker'"
            :sort="sort"/>
        </div>
      </th>

      <th
        scope="col"
        class="text-left text-base font-bold whitespace-nowrap border-r border-gray-100">
        <!-- Market dominance is calculated by taking a coins market capitalization (market cap), and dividing it by the total market capitalization. <a class='hover:underline with-link-icon' target='_blank' href='https://lunarcrush.com/faq/what-is-market-dominance'>Read&nbsp;more</a> -->
        <div
          class="px-3 py-1 flex items-center justify-between select-none leading-5 cursor-pointer"
          @click="toggleSortDirection('market_dominance')">
          Market <br> dominance

          <SocialActivitySortIcon
            :field="'market_dominance'"
            :sort="sort"/>
        </div>
      </th>

      <th
        scope="col"
        class="text-left text-base font-bold whitespace-nowrap border-r border-gray-100">
        <div
          class="p-3 flex items-center justify-between select-none leading-5 cursor-pointer"
          @click="toggleSortDirection('news_sum')">
          News

          <SocialActivitySortIcon
            :field="'news_sum'"
            :sort="sort"/>
        </div>
      </th>

      <th
        scope="col"
        class="text-left text-base font-bold whitespace-nowrap border-r border-gray-100">
        <div
          class="px-3 py-1 flex items-center justify-between select-none leading-5 cursor-pointer"
          @click="toggleSortDirection('reddit_posts_sum')">
          Reddit <br> posts

          <SocialActivitySortIcon
            :field="'reddit_posts_sum'"
            :sort="sort"/>
        </div>
      </th>

      <th
        scope="col"
        class="text-left text-base font-bold whitespace-nowrap border-r border-gray-100">
        <div
          class="p-3 flex items-center justify-between select-none leading-5 cursor-pointer"
          @click="toggleSortDirection('tweets_sum')">
          Tweets

          <SocialActivitySortIcon
            :field="'tweets_sum'"
            :sort="sort"/>
        </div>
      </th>

      <th
        scope="col"
        class="text-left text-base font-bold whitespace-nowrap border-r border-gray-100">
        <div class="px-3 py-1 flex items-center justify-between leading-5">
          Price with <br> Social volume
        </div>
      </th>

      <th
        scope="col"
        class="text-left text-base font-bold whitespace-nowrap border-r border-gray-100">
        <div
          class="px-3 py-1 flex items-center justify-between select-none leading-5 cursor-pointer"
          @click="toggleSortDirection('price_change')">
          Price <br> change

          <SocialActivitySortIcon
            :field="'price_change'"
            :sort="sort"/>
        </div>
      </th>

      <th
        scope="col"
        class="text-left text-base font-bold whitespace-nowrap border-gray-100">
        <div class="p-3 flex items-center justify-between leading-5">
          RSI
        </div>
      </th>
    </tr>
  </thead>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useSocialActivityStore } from '@/stores/social-activity'
import SocialActivitySortIcon from '@/components/SocialActivity/Includes/SocialActivitySortIcon'

export default {
  name: 'SocialActivityTableHead',
  components: {
    SocialActivitySortIcon
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(useSocialActivityStore, [
      'items',
      'sort'
    ])
  },
  mounted () {
  },
  methods: {
    ...mapActions(useSocialActivityStore, [
      'toggleSortDirection'
    ])
  }
}
</script>

