<template>
  <div class="relative">
    <div class="absolute top-1 left-20 z-10">
      <div
        v-for="(item, key) in legend"
        :key="key"
        class="text-xs text-slate-900 text-opacity-60">
        <div class="flex items-center">
          <div
            :style="{background: item.color}"
            class="rounded-full w-1.5 h-1.5 mr-1.5"/>

          <p v-html="item.text"/>
        </div>
      </div>
    </div>

    <div ref="chart"/>
  </div>
</template>

<script>

import { createChart } from 'lightweight-charts'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { min, max } from 'radash'


import { tickStep } from 'd3-array'

dayjs.extend(utc)

export default {
  name: 'CoinCardChart',
  components: {},
  props: {
    prices: {
      type: Array,
      default: () => ([]),
      required: true
    },
    socialVolume: {
      type: Array,
      default: () => ([]),
      required: true
    }
  },
  data () {
    return {
      legend: {
        price: {
          color: 'rgba(38,198,218, 1)',
          text: '<b>Price<b/>'
        },
        socialVolume: {
          color: '#1DA1F2',
          text: '<b>Social volume</b>'
        }
      }
    }
  },
  computed: {},
  mounted () {
    const el = this.$refs.chart
    const width = el.offsetWidth
    const height = 300

    const chart = createChart(el, {
      width,
      height,
      rightPriceScale: {
        visible: true,
        borderColor: 'rgba(197, 203, 206, 1)'
      },
      leftPriceScale: {
        visible: true,
        borderColor: 'rgba(197, 203, 206, 1)'
      },
      timeScale: {
        // borderColor: 'rgba(197, 203, 206, 1)',
        borderVisible: false,
        timeVisible: true,
        secondsVisible: false
      },
      grid: {
        horzLines: {
          color: '#eee',
          visible: false
        },
        vertLines: {
          color: '#ffffff'
        }
      },
      crosshair: {
        vertLine: {
          width: 5,
          color: 'rgba(32, 38, 46, 0.1)',
          style: 0
        },
        horzLine: {
          visible: false,
          labelVisible: false
        }
      }
    })

    const minValue = min(this.prices, f => f.value).value
    const maxValue = max(this.prices, f => f.value).value

    const areaSeries = chart.addAreaSeries({
      topColor: 'rgba(38,198,218, 0.56)',
      bottomColor: 'rgba(38,198,218, 0.04)',
      lineColor: 'rgba(38,198,218, 1)',
      lineWidth: 2,
      priceLineVisible: false,
      crosshairMarkerVisible: true,
      lastValueVisible: true,
      priceFormat: {
        type: 'price',
        minMove: tickStep(minValue, maxValue, 5) // https://github.com/d3/d3-array/blob/main/README.md#tickStep
      }
    })

    const prices = this.prices.map(item => {
      return {
        value: item.value,
        time: item.time / 1000
      }
    })

    areaSeries.setData(prices)

    // To add price change to chart
    const extraPricesData = new Map()

    prices.forEach((datapoint, index) => {
      if (index > 0) {
        const a = prices[index].value
        const b = prices[index - 1].value
        const priceChange = (a - b) / b * 100

        extraPricesData.set(datapoint.time, priceChange.toFixed(2))
      } else {
        extraPricesData.set(datapoint.time, 0)
      }
    })

    const lineSeries = chart.addLineSeries({
      lineWidth: 2,
      priceScaleId: 'left',
      color: '#1DA1F2',
      priceLineVisible: false,
      crosshairMarkerVisible: true,
      lastValueVisible: false,
      priceFormat: {
        type: 'volume'
      }
    })

    const socialVolume = this.socialVolume.map(item => {
      return {
        value: item.value,
        time: item.time / 1000
      }
    })

    lineSeries.setData(socialVolume)

    chart.timeScale().fitContent()

    chart.subscribeCrosshairMove((param) => {
      if (param.time) {
        const socialVolume = param.seriesData.get(lineSeries)
        const price = param.seriesData.get(areaSeries)
        let priceChange = extraPricesData.get(param.time)

        priceChange = priceChange > 0
          ? `(<span class="text-[#4fc280]">+${priceChange}%</span>)`
          : `(<span class="text-[#d7455b]">${priceChange}%</span>)`

        this.legend.price.text = `<b>Price</b> ${price.value} ${priceChange}`
        this.legend.socialVolume.text = '<b>Social volume</b> ' + socialVolume.value
      }
      else {
        this.legend.price.text = '<b>Price<b/>'
        this.legend.socialVolume.text = '<b>Social volume</b>'
      }
    })
  },
  methods: {}
}
</script>

