<template>
  <tbody class="divide-y divide-gray-100 bg-white">
    <tr
      v-for="(item, index) in items"
      :key="item.ticker + prefix"
      :class="{ 'bg-gray-50': item.starred }">
      <td
        class="whitespace-nowrap p-3 border-r border-gray-100 text-center"
        @click="starTicker({ ticker: item.ticker, starred: item.starred })">
        <div class="flex flex-col items-center cursor-pointer">
          {{ index + 1 }}

          <InlineSvg
            v-show="item.starred"
            class="inline-block w-4 h-4 text-gray-200"
            :src="require('@/assets/svg/star-filled.svg')"/>

          <InlineSvg
            v-show="!item.starred"
            class="inline-block w-4 h-4 text-gray-400"
            :src="require('@/assets/svg/star.svg')"/>
        </div>
      </td>

      <td class="whitespace-nowrap border-r border-gray-100 text-left">
        <div class="px-2.5 py-3 flex items-center">
          <button
            :data-clipboard-text="item.ticker"
            class="js-clipboard flex items-center justify-center"
            @click="clipboard">
            <SocialActivityCoinLogo
              class="w-8 h-8 mr-2"
              :alt-text="`${item.name} (${item.ticker})`"
              :ticker="item.ticker"/>
          </button>

          <router-link
            class=" flex flex-col"
            :to="item.ticker">
            <div class="flex items-center">
              <a :title="item.name">{{ item.ticker }}</a>

              <span class="text-xs ml-1 text-gray-400">{{ item.price }}</span>
            </div>

            <p class="text-gray-400 text-xs">{{ item.name }}</p>
          </router-link>
        </div>
      </td>

      <td class="whitespace-nowrap border-r border-gray-100 text-left p-3">
        {{ item.market_dominance ? item.market_dominance : '–' }}
      </td>

      <td class="whitespace-nowrap border-r border-gray-100 text-left p-3">
        {{ item.news_sum ? item.news_sum : '–' }}
      </td>

      <td class="whitespace-nowrap border-r border-gray-100 text-left p-3">
        <VlTooltip
          align="center"
          placement="top"
          gap="3"
          :class="{ 'mt-1': item.reddit_posts_sum }">
          {{ item.reddit_posts_sum ? item.reddit_posts_sum : '–' }}

          <SocialActivityRedditWeight
            v-if="item.reddit_posts_sum"
            :coin="item"/>

          <template #content>
            <div>Comments: <span class="font-semibold">{{ item.reddit_comments_sum }}</span></div>
          </template>
        </VlTooltip>
      </td>

      <td class="whitespace-nowrap border-r border-gray-100 text-left p-3">
        <div class="flex items-center justify-between">
          <VlTooltip
            align="center"
            placement="top"
            gap="3"
            :class="{ 'mt-1': item.tweets_sum }">
            {{ item.tweets_sum ? item.tweets_sum : '–' }}

            <SocialActivityTweetsWeight
              v-if="item.tweets_sum"
              :coin="item"/>

            <template #content>
              <div>Quotes: <span class="font-semibold">{{ item.tweet_quotes_sum }}</span></div>
              <div>Retweets: <span class="font-semibold">{{ item.tweet_retweets_sum }}</span></div>
              <div>Replies: <span class="font-semibold">{{ item.tweet_replies_sum }}</span></div>
              <div>Favorites: <span class="font-semibold">{{ item.tweet_favorites_sum }}</span></div>
            </template>
          </VlTooltip>

          <SocialActivitySentimentPie
            :coin="item"
            :sentiments="prepareSentimentData(item)"/>
        </div>
      </td>

      <td class="whitespace-nowrap border-r border-gray-100 text-left w-[193px]">
        <SocialActivitySmallChart
          :key="item.ticker + prefix + item.price_change + item.tweets_sum"
          :prices="item.prices_arr"
          :tweets="item.social_volume_arr"
          :price-change="item.price_change"/>
      </td>

      <td class="whitespace-nowrap border-r border-gray-100 text-left p-3">
        <template v-if="isNumber(item.price_change)">
          <span v-show="item.price_change > 0">+</span>{{ item.price_change }}%
        </template>

        <template v-else>
          –
        </template>
      </td>

      <td class="whitespace-nowrap border-gray-100 text-left px-3 py-1 ">
        <a :title="`RSI: ${item.prices_rsi_arr.at(-1)?.value}`">
          <template v-if="item.prices_rsi_arr.at(-1)?.value > 70">
            <InlineSvg
              class="w-8 h-8 inline-block text-brand-green"
              :src="require('@/assets/svg/stock/overbought.svg')"/>
          </template>

          <template v-else-if="item.prices_rsi_arr.at(-1)?.value < 30">
            <InlineSvg
              class="w-8 h-8 inline-block text-brand-red"
              :src="require('@/assets/svg/stock/oversold.svg')"/>
          </template>

          <template v-else>
            <InlineSvg
              class="w-8 h-8 inline-block"
              :src="require('@/assets/svg/stock/sideway.svg')"/>
          </template>
        </a>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useSocialActivityStore } from '@/stores/social-activity'
import { uid, isNumber } from 'radash'
import SocialActivitySmallChart from '@/components/SocialActivity/Includes/SocialActivitySmallChart'
import SocialActivityCoinLogo from '@/components/SocialActivity/Includes/SocialActivityCoinLogo'
import SocialActivitySentimentPie from '@/components/SocialActivity/Includes/SocialActivitySentimentPie.vue'
import SocialActivityTweetsWeight from '@/components/SocialActivity/Includes/SocialActivityTweetsWeight.vue'
import SocialActivityRedditWeight from '@/components/SocialActivity/Includes/SocialActivityRedditWeight.vue'
import VlTooltip from '@/components/Functional/VlTooltip.vue'

export default {
  name: 'SocialActivityTableBody',
  components: {
    SocialActivitySmallChart,
    SocialActivityCoinLogo,
    SocialActivitySentimentPie,
    SocialActivityTweetsWeight,
    SocialActivityRedditWeight,
    VlTooltip
  },
  data () {
    return {
      prefix: uid(9)
    }
  },
  computed: {
    ...mapState(useSocialActivityStore, [
      'items',
      'filtersCurrentValues'
    ]),
    filtersString () {
      return JSON.stringify(this.filtersCurrentValues)
    }
  },
  watch: {
    async filtersString (value, oldValue) {
      if (value !== oldValue) {
        this.prefix = uid(9)
      }
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions(useSocialActivityStore, [
      'starTicker'
    ]),
    isNumber (value) {
      return isNumber(value)
    },
    clipboard () {
      this.$toast.open({
        message: 'Ticker copied to clipboard!',
        type: 'success',
        duration: 3000,
        dismissible: true
      })
    },
    prepareSentimentData (coin) {
      return [
        {
          name: 'Very Bearish',
          value: coin.tweet_sentiment1_sum,
          color: '#d7455b'
        },
        {
          name: 'Bearish',
          value: coin.tweet_sentiment2_sum,
          color: '#FF9914'
        },
        {
          name: 'Neutral',
          value: coin.tweet_sentiment3_sum,
          color: '#f3f4f6'
        },
        {
          name: 'Bullish',
          value: coin.tweet_sentiment4_sum,
          color: '#AFFC41'
        },
        {
          name: 'Very Bullish',
          value: coin.tweet_sentiment5_sum,
          color: '#4fc280'
        }
      ]
    }
  }
}
</script>
