<template>
  <article class="mx-auto max-w-3xl px-5 mt-8 mb-12 h-full">
    <div
      v-show="dataFetching"
      class="px-4 py-20 flex items-center justify-center">
      <span class="loader w-24 h-24 !border-8"/>
    </div>

    <template v-if="!dataFetching">
      <template v-if="coinLoadingState === 'loaded'">
        <CoinCardHeader
          class="mb-10"
          :coin="coin"/>

        <CoinCardBody
          class="mb-10"
          :coin="coin"/>
      </template>

      <div v-else-if="coinLoadingState === 'error'">
        Smt wrong with coin data
      </div>

      <CoinCardNews
        v-if="newsLoadingState === 'loaded'"
        :news="news"/>

      <div v-else-if="newsLoadingState === 'error'">
        Smt wrong with news data
      </div>
    </template>
  </article>
</template>

<script>
import CoinCardHeader from '@/components/CoinCard/CoinCardHeader'
import CoinCardBody from '@/components/CoinCard/CoinCardBody'
import CoinCardNews from '@/components/CoinCard/CoinCardNews'
import { get } from '@/helpers/fetch'

export default {
  name: 'CoinCardView',
  components: {
    CoinCardHeader,
    CoinCardBody,
    CoinCardNews
  },
  data () {
    return {
      coin: {},
      news: [],

      dataFetching: true,
      coinLoadingState: 'loading',
      newsLoadingState: 'loading'
    }
  },
  computed: {},
  beforeCreate () {
    document.title = `CAT :: ${this.$route.params.ticker.toUpperCase()}`
  },
  async mounted () {
    const ticker = this.$route.params.ticker

    // Order of then and catch: https://stackoverflow.com/a/42028776
    // Promise allSettled explanation: https://stackoverflow.com/a/59436488
    await Promise.allSettled([
      this.getCoin(ticker)
        .then(() => { this.coinLoadingState = 'loaded' })
        .catch(() => { this.coinLoadingState = 'error' }),
      this.getNews(ticker)
        .then(() => { this.newsLoadingState = 'loaded' })
        .catch(() => { this.newsLoadingState = 'error' })
    ])

    this.dataFetching = false
  },
  methods: {
    async getCoin (ticker) {
      const response = await get(`/data/social-activity/${ticker}`)

      if (response) {
        this.coin = response
      }
    },
    async getNews (ticker) {
      const response = await get('/coins/crypto-panic/news', {
        coin: ticker,
        filter: 'hot'
      })

      if (response) {
        this.news = response
      }
    }
  }
}
</script>

