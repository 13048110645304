<template>
  <div class="flex items-center justify-between mb-10 relative">
    <div class="flex items-center gap-4">
      <CoinCardLogo
        class="w-16 h-16"
        :alt="coin.ticker + 'ticker'"
        :ticker="coin.ticker.toUpperCase()"/>

      <div>
        <div class="flex items-center">
          <h1 class="uppercase text-slate-600">
            {{ coin.ticker }}
          </h1>

          <div
            :class="[coin.price_change > 0 ? 'text-[#4fc280]' : 'text-[#d7455b]']"
            class="text-lg ml-2">
            {{ coin.price_change }}%
          </div>
        </div>

        <p class="text-sm text-slate-600">
          {{ coin.name }} <span class="text-slate-700 font-semibold">${{ coin.price }}</span>
        </p>
      </div>
    </div>

    <div class="flex gap-3 flex-wrap items-center pl-4 max-w-sm">
      <a
        v-if="metadata.github_link"
        target="_blank"
        :href="metadata.github_link"
        title="Github">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/github.svg')"/>
      </a>

      <a
        v-if="metadata.website_link"
        target="_blank"
        :href="metadata.website_link"
        title="Website">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/website.svg')"/>
      </a>

      <a
        v-if="metadata.whitepaper_link"
        target="_blank"
        :href="metadata.whitepaper_link"
        title="Whitepaper">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/whitepaper.svg')"/>
      </a>

      <a
        v-if="metadata.twitter_link"
        target="_blank"
        :href="metadata.twitter_link"
        title="Twitter">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/twitter.svg')"/>
      </a>

      <a
        v-if="metadata.reddit_link"
        target="_blank"
        :href="metadata.reddit_link"
        title="Reddit">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/reddit.svg')"/>
      </a>

      <a
        v-if="metadata.telegram_link"
        target="_blank"
        :href="metadata.telegram_link"
        title="Telegram">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/telegram.svg')"/>
      </a>

      <a
        v-if="metadata.youtube_link"
        target="_blank"
        :href="metadata.youtube_link"
        title="Youtube">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/youtube.svg')"/>
      </a>

      <a
        v-if="metadata.blog_link"
        target="_blank"
        :href="metadata.blog_link"
        title="Blog">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/blog.svg')"/>
      </a>

      <a
        v-if="metadata.videos"
        target="_blank"
        :href="metadata.videos"
        title="Videos">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/circle-play.svg')"/>
      </a>

      <a
        v-if="metadata.instagram_link"
        target="_blank"
        :href="metadata.instagram_link"
        title="Instagram">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/instagram.svg')"/>
      </a>

      <a
        v-if="metadata.facebook_link"
        target="_blank"
        :href="metadata.facebook_link"
        title="Facebook">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/facebook.svg')"/>
      </a>

      <a
        v-if="metadata.pinterest_link"
        target="_blank"
        :href="metadata.pinterest_link"
        title="Pinterest">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/pinterest.svg')"/>
      </a>

      <a
        v-if="metadata.linkedin_link"
        target="_blank"
        :href="metadata.linkedin_link"
        title="Linkedin">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/linkedin.svg')"/>
      </a>

      <a
        v-if="metadata.weibo_link"
        target="_blank"
        :href="metadata.weibo_link"
        title="Weibo">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/weibo.svg')"/>
      </a>

      <a
        v-if="metadata.discord_link"
        target="_blank"
        :href="metadata.discord_link"
        title="Discord">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/discord.svg')"/>
      </a>

      <a
        v-if="metadata.gitlab_link"
        target="_blank"
        :href="metadata.gitlab_link"
        title="Gitlab">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/gitlab.svg')"/>
      </a>

      <a
        v-if="metadata.forum_link"
        target="_blank"
        :href="metadata.forum_link"
        title="Forum">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/forum.svg')"/>
      </a>

      <a
        v-if="metadata.wikipedia_link"
        target="_blank"
        :href="metadata.wikipedia_link"
        title="Wikipedia">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/wikipedia.svg')"/>
      </a>

      <a
        v-if="metadata.tradingview_link"
        target="_blank"
        :href="metadata.tradingview_link"
        title="TradingView">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/tradingview.svg')"/>
      </a>

      <a
        v-if="metadata.coinmarketcap_link"
        target="_blank"
        :href="metadata.coinmarketcap_link"
        title="Coinmarketcap">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/coinmarketcap.svg')"/>
      </a>

      <a
        v-if="metadata.lunarcrush_link"
        target="_blank"
        :href="metadata.lunarcrush_link"
        title="Lunarcrush">
        <InlineSvg
          class="inline-block w-7 h-7 text-slate-600 hover:text-sky-600"
          :src="require('@/assets/svg/social/lunarcrush.svg')"/>
      </a>
    </div>
  </div>
</template>

<script>
import CoinCardLogo from '@/components/CoinCard/Includes/CoinCardLogo'
import formatDate from '@/mixins/formatDate'

export default {
  name: 'CoinCardMainChart',
  components: {
    CoinCardLogo
  },
  mixins: [formatDate],
  props: {
    coin: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    prices () {
      return this.coin.data.map(item => {
        return {
          value: item.close,
          time: item.time
        }
      })
    },
    metadata () {
      return this.coin.metadata
    }
  },
  mounted () {
  },
  methods: {}
}
</script>

