<template>
  <div>
    <div
      v-show="!loaded"
      class="px-4 py-20 flex items-center justify-center">
      <span class="w-16 h-16 loader"/>
    </div>

    <div
      v-if="loaded"
      class="mx-auto max-w-[66rem] flex flex-col mt-8 mb-12">
      <div class="overflow-x-auto">
        <div class="inline-block min-w-full align-middle">
          <div class="overflow-hidden">
            <div class="mx-auto max-w-[66rem] mb-6 px-5">
              <h1 class="mb-4">
                Social activity
              </h1>

              <SocialActivityFilters/>
            </div>

            <table class="min-w-full divide-y divide-gray-100 bg-white">
              <SocialActivityTableHead/>

              <SocialActivityTableBody/>
            </table>
          </div>
        </div>
      </div>

      <div
        v-if="!items.length && loadingStatus === 'loaded-all'"
        class="border-y border-gray-100 px-3 py-4 text-center">
        No items found
      </div>

      <div
        v-else-if="loadingStatus === 'loaded-all'"
        class="border-y border-gray-100 px-3 py-4 text-center">
        Loaded all
      </div>

      <div
        v-show="loadingStatus === 'loading-more' || loadingStatus === 'loading'"
        class="border-y border-gray-100 px-3 h-[54px] flex items-center justify-center">
        <span class="w-4 h-4 loader !border-2"/>
      </div>

      <ObserverHelper @intersect="intersected"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useSocialActivityStore } from '@/stores/social-activity'
import { useAuthStore } from '@/stores/auth'
import ObserverHelper from '@/components/Functional/ObserverHelper'
import SocialActivityTableHead from '@/components/SocialActivity/Includes/SocialActivityTableHead'
import SocialActivityTableBody from '@/components/SocialActivity/Includes/SocialActivityTableBody'
import SocialActivityFilters from '@/components/SocialActivity/SocialActivityFilters'

export default {
  name: 'SocialActivity',
  components: {
    SocialActivityTableHead,
    SocialActivityTableBody,
    ObserverHelper,
    SocialActivityFilters
  },
  props: {
    type: {
      type: String,
      default: 'main'
    }
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapState(useSocialActivityStore, [
      'loadingStatus',
      'sortString'
    ]),
    ...mapWritableState(useSocialActivityStore, [
      'items',
      'filters'
    ]),
    ...mapState(useAuthStore, [
      'userSettings'
    ])
  },
  watch: {
    sortString (value, oldValue) {
      if (value !== oldValue) {
        this.getSocialActivityData('sort')
      }
    }
  },
  created () {
    document.title = 'CAT :: Social activity'
  },
  async mounted () {
    this.items = []

    await this.getSocialActivityData('first_load')

    this.loaded = true
  },
  methods: {
    ...mapActions(useSocialActivityStore, [
      'getSocialActivityData'
    ]),
    async intersected () {
      if (['loaded-all', 'loading-more', 'loading'].includes(this.loadingStatus)) {
        return
      }

      await this.getSocialActivityData('load_more')
    }
  }
}
</script>

