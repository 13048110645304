<template>
  <div class="max-w-4xl mx-auto px-5">
    <div
      v-show="!loaded"
      class="px-4 py-20 flex items-center justify-center">
      <span class="w-16 h-16 loader"/>
    </div>

    <div
      v-if="loaded"
      class="mt-8 mb-12">
      <h1 class="mb-4">
        Price Changes
      </h1>

      <PriceChangesHeader/>

      <PriceChangesFilters/>

      <PriceChangesTable/>
    </div>
  </div>
</template>

<script>
import { usePriceChangesStore } from '@/stores/price-changes'
import { mapActions } from 'pinia'
import PriceChangesHeader from '@/components/PriceChanges/PriceChangesHeader.vue'
import PriceChangesFilters from '@/components/PriceChanges/PriceChangesFilters.vue'
import PriceChangesTable from '@/components/PriceChanges/PriceChangesTable'

export default {
  name: 'PriceChangesView',
  components: {
    PriceChangesHeader,
    PriceChangesFilters,
    PriceChangesTable
  },
  data () {
    return {
      loaded: false
    }
  },
  created () {
    document.title = 'CAT :: Price Changes'
  },
  async mounted () {
    await this.getPriceChanges()
      .finally(() => {
        this.loaded = true
      })
  },
  methods: {
    ...mapActions(usePriceChangesStore, [
      'getPriceChanges'
    ])
  }
}
</script>
