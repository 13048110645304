<template>
  <div class="relative">
    <div class="absolute top-1 left-20 z-10">
      <div
        v-for="(item, index) in legend"
        :key="index"
        class="text-xs text-slate-900 text-opacity-60">
        <div class="flex items-center">
          <div
            :style="{background: item.color}"
            class="rounded-full w-1.5 h-1.5 mr-1.5"/>

          <p v-html="item.text"/>
        </div>
      </div>
    </div>

    <div ref="chart"/>
  </div>
</template>

<script>

import { createChart } from 'lightweight-charts'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { tickStep } from 'd3-array'
import { min, max } from 'radash'

dayjs.extend(utc)

let chart = null
let lines = []

export default {
  name: 'CorrelationsTable',
  components: {},
  props: {
    coins: {
      type: Array,
      default: () => ([]),
      required: true
    }
  },
  data () {
    return {
      legend: []
    }
  },
  computed: {},
  watch: {
    coins (value) {
      if (value.length === 2) {
        chart.remove()
        lines = []
        this.createChart()
      }
    }
  },
  mounted () {
    this.createChart()
  },
  methods: {
    createChart () {
      const el = this.$refs.chart
      const width = el.offsetWidth
      const height = 300

      chart = createChart(el, {
        width,
        height,
        rightPriceScale: {
          visible: true,
          borderColor: '#d6dcde'
        },
        leftPriceScale: {
          visible: true,
          borderColor: '#d6dcde'
        },
        timeScale: {
          borderColor: '#d6dcde',
          timeVisible: true,
          secondsVisible: false
        },
        crosshair: {
          vertLine: {
            width: 5,
            color: 'rgba(32, 38, 46, 0.1)',
            style: 0
          },
          horzLine: {
            visible: false,
            labelVisible: false
          }
        }
      })

      function getDigits (v) {
        const s = v.toString()
        const i = s.indexOf('.') + 1

        return i && s.length - i
      }

      for (const [index, coin] of this.coins.entries()) {
        const minClose = min(coin.candles, f => f.close).close
        const maxClose = max(coin.candles, f => f.close).close

        const lineSeries = chart.addLineSeries({
          lineWidth: 2,
          priceScaleId: index === 0 ? 'left' : 'right',
          color: index === 0 ? '#1DA1F2' : '#7E57C2',
          priceLineVisible: false,
          crosshairMarkerVisible: true,
          lastValueVisible: true,
          autoScale: false,
          priceFormat: {
            type: 'price',
            precision: getDigits(coin.candles[0].close),
            minMove: tickStep(minClose, maxClose, 5) // https://github.com/d3/d3-array/blob/main/README.md#tickStep
          }
        })

        lines.push({
          color: index === 0 ? '#1DA1F2' : '#7E57C2',
          ticker: coin.ticker,
          series: lineSeries
        })

        this.legend[index] = {
          text: `<b>${coin.ticker}</b>`,
          color: index === 0 ? '#1DA1F2' : '#7E57C2'
        }

        const items = coin.candles.map(item => {
          return {
            value: item.close,
            time: item.time
          }
        })

        lineSeries.setData(items)
      }

      chart.timeScale().fitContent()

      chart.subscribeCrosshairMove((param) => {
        if (param.time) {
          for (const [index, item] of lines.entries()) {
            const legendItem = param.seriesData.get(item.series)

            if (legendItem) {
              this.legend[index] = {
                text: `<b>${item.ticker}</b> ${legendItem.value}`,
                color: item.color
              }
            } else {
              this.legend[index] = {
                text: `<b>${item.ticker}</b>`,
                color: item.color
              }
            }
          }
        }
        else {
          for (const [index, item] of lines.entries()) {
            this.legend[index] = {
              text: `<b>${item.ticker}</b>`,
              color: item.color
            }
          }
        }
      })
    }
  }
}
</script>

