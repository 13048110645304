<template>
  <div class="max-w-4xl mx-auto px-5">
    <div
      v-show="!loaded"
      class="px-4 py-20 flex items-center justify-center">
      <span class="w-16 h-16 loader"/>
    </div>

    <div
      v-if="loaded"
      class="mt-8 mb-12">
      <h1 class="mb-4">
        Correlations
      </h1>

      <CorrelationsFilters/>

      <CorrelationsTable/>
    </div>
  </div>
</template>

<script>
import { useCorrelationsStore } from '@/stores/correlations'
import { mapActions } from 'pinia'
import CorrelationsFilters from '@/components/Correlations/CorrelationsFilters.vue'
import CorrelationsTable from '@/components/Correlations/CorrelationsTable.vue'

export default {
  name: 'CorrelationsView',
  components: {
    CorrelationsTable,
    CorrelationsFilters
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {},
  created () {
    document.title = 'CAT :: Correlations'
  },
  async mounted () {
    await this.getCorrelations()
      .finally(() => {
        this.loaded = true
      })
  },
  methods: {
    ...mapActions(useCorrelationsStore, [
      'getCorrelations'
    ])
  }
}
</script>
