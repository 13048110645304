import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default {
  methods: {
    formatDateUTC (date) {
      return dayjs.utc(date).format('D MMM YYYY HH:mm')
    },
    formatUnixUTC (seconds) {
      return dayjs.unix(seconds).utc().format('D MMM YYYY HH:mm')
    }
  }
}
