import { defineStore } from 'pinia'
import { get } from '@/helpers/fetch'

export const useCorrelationsStore = defineStore({
  id: 'correlations',
  state: () => ({
    grid: null,
    metadata: {},
    filters: { daterange: { from: null, to: null } },
    timeframe: '15m',
    filters_current_values: {}
  }),
  actions: {
    async getCorrelations () {
      const response = await get('/coins/correlations/table', {
        timeframe: this.timeframe,
        filters: JSON.stringify(this.filters)
      })

      this.grid = response.grid
      this.metadata = response.metadata
      this.filters_current_values = response.filters_current_values
    }
  }
})
