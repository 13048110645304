<template>
  <div class="container py-5 px-5">
    <h1>Account</h1>
  </div>
</template>

<script>

export default {
  name: 'AccountView',
  components: {},
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

