<template>
  <div
    :class="{
      'bg-red-50': type === 'error',
      'bg-green-50': type === 'success',
      'bg-gray-50': type !== 'error' && type !== 'success'
    }"
    class="rounded p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <svg
          v-if="type === 'error'"
          class="h-5 w-5 text-red-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
        </svg>

        <svg
          v-if="type === 'success'"
          class="h-5 w-5 text-green-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
        </svg>
      </div>

      <div class="ml-3">
        <h3
          :class="{
            'text-red-800': type === 'error',
            'text-green-800': type === 'success'
          }"
          class="text-sm font-medium">
          {{ heading }}
        </h3>

        <div
          v-if="text"
          :class="{
            'text-red-700': type === 'error',
            'text-green-700': type === 'success'
          }"
          class="mt-2 text-sm">
          <p>{{ text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AlertMessage',
  components: {},
  props: {
    type: {
      type: String,
      default: ''
    },
    heading: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

