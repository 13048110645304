<template>
  <div>
    <h2 class="mb-4">
      <!-- Important news from <a class='hover:underline with-link-icon' target='_blank' href='https://cryptopanic.com/'>CryptoPanic</a> API -->
      <span class="with-circle-info-icon">
        Hot news
      </span>
    </h2>

    <div class="space-y-1.5">
      <div
        v-for="(item, index) in news"
        :key="'news' + index">
        <a
          class="hover:text-blue-300"
          target="_blank"
          :href="item.url">
          {{ item.title }} <br>

          <time class="text-slate-400 text-xs whitespace-nowrap">{{ formatDateUTC(item.date * 1000) }}</time>
        </a>
      </div>
    </div>

    <p v-show="news && !news.length">
      No news
    </p>
  </div>
</template>

<script>

import formatDate from '@/mixins/formatDate'

export default {
  name: 'CoinCardNews',
  components: {},
  mixins: [formatDate],
  props: {
    news: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

