import { defineStore } from 'pinia'

export const useAppStore = defineStore({
  id: 'app',
  state: () => ({
    sidebarIsOpen: false,
    debugOpen: false,
    allowNotificationIsOpen: false,
    allowNotificationWasOpened: false
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: [
          'sidebarIsOpen',
          'debugOpen'
        ]
      }
    ]
  },
  actions: {}
})
