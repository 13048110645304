<template>
  <div class="relative">
    <img
      class="block h-full w-full p-0.5"
      :src="image"
      :title="altText"
      :alt="altText"
      :class="[
        { 'text-[0] text-transparent': !isLoaded },
        { 'invisible': !isLoaded || constantLoading }
      ]"
      @load="load">

    <div
      v-show="!isLoaded || constantLoading"
      class="absolute inset-0 spinner"/>
  </div>
</template>

<script>

export default {
  name: 'SocialActivityCoinLogo',
  components: {},
  props: {
    ticker: {
      type: String,
      default: '',
      required: true
    },
    altText: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {
      image: null,
      isLoaded: false,
      constantLoading: true
    }
  },
  computed: {},
  beforeMount () {
    // https://stackoverflow.com/questions/40491506/vue-js-dynamic-images-not-working
    try {
      this.image = require('@/assets/svg/cryptologos/' + this.ticker + '.svg')
    } catch (e) {
      this.image = require('@/assets/svg/default-logo.svg')
    }
  },
  mounted () {
    setTimeout(() => {
      this.constantLoading = false
    }, 200)
  },
  methods: {
    load () {
      this.isLoaded = true
    }
  }
}
</script>

<style>
  /* https://stephanwagner.me/only-css-loading-spinner */
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  .spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    margin-top: -50%;
    margin-left: -50%;
    border-radius: 50%;
    border: 1px solid #ccc;
    border-top-color: #07d;
    animation: spinner .6s linear infinite;
  }
</style>

