<template>
  <div class="flex flex-col">
    <div class="overflow-x-auto mb-6">
      <div class="inline-block min-w-full py-2 align-middle">
        <div class="overflow-hidden">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th class="bg-[#f6f8fa]"/>

                <th
                  v-for="(item, index) in metadata.pairs"
                  :key="index"
                  class="bg-[#f6f8fa]">
                  {{ item }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(pair, index) in metadata.pairs"
                :key="index">
                <th class="bg-[#f6f8fa]">
                  {{ pair }}
                </th>

                <td
                  v-for="(corr, i) in grid[index]"
                  :key="i"
                  :style="{ backgroundColor: corr > 0 ? `rgba(76, 180, 170, ${corr})` : `rgba(187, 68, 48, ${Math.abs(corr)})` }"
                  class="cursor-pointer"
                  @click="selectPair({
                    coins: `${pair},${metadata.pairs[i]}`,
                    startTime: filters_current_values.start_date * 1000,
                    finishTime: filters_current_values.finish_date * 1000
                  })">
                  <VlTooltip
                    align="center"
                    placement="top"
                    gap="3"
                    class="w-full"
                    :content="`${pair} | ${metadata.pairs[i]}`">
                    {{ corr }}
                  </VlTooltip>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <CorrelationsChart
      v-if="coinsToDraw.length"
      :coins="coinsToDraw"/>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useCorrelationsStore } from '@/stores/correlations'
import CorrelationsChart from '@/components/Correlations/includes/CorrelationsChart'
import VlTooltip from '@/components/Functional/VlTooltip.vue'
import { get } from '@/helpers/fetch'

export default {
  name: 'CorrelationsTable',
  components: {
    CorrelationsChart,
    VlTooltip
  },
  data () {
    return {
      coinsToDraw: []
    }
  },
  computed: {
    ...mapState(useCorrelationsStore, [
      'metadata',
      'grid',
      'filters_current_values'
    ])
  },
  created () {},
  mounted () {},
  methods: {
    async selectPair ({ coins, startTime, finishTime }) {
      const { items } = await get('/coins/candles/data', {
        coins: coins,
        start_time: startTime,
        finish_time: finishTime,
        fields: 'close',
        timeframe: '10m'
      })

      if (items) {
        this.coinsToDraw = items
      }
    }
  }
}
</script>

<style scoped>
  tr:nth-child(2n) {
    background: initial;
  }

  td, th {
    border: 1px solid white;
    white-space: nowrap;
    @apply py-2 px-2
  }
</style>
