import { defineStore } from 'pinia'
import app from '@/main'
import { useSocialActivityStore } from '@/stores/social-activity'
import { post } from '@/helpers/fetch'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    token: null,
    userSettings: {},
    isSocketConnected: false,
    rights: null, // admin,user,guest
    username: null,
    pathToRedirectAfterLogin: null
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: [
          'token',
          'userSettings'
        ]
      }
    ]
  },
  actions: {
    async authenticate ({ username, password }) {
      try {
        const response = await post('/login', {
          username: username,
          password: password
        })

        if (response.token) {
          this.token = response.token
          this.userSettings = response.user_settings
          this.rights = response.rights
          this.username = response.username

          const socialActivityStore = useSocialActivityStore()
          socialActivityStore.filters.market_dominance = response.user_settings.filters.market_dominance

          if (this.pathToRedirectAfterLogin) {
            await this.router.push(this.pathToRedirectAfterLogin)

            this.pathToRedirectAfterLogin = null
          } else {
            await this.router.push('/')
          }
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    logout () {
      this.token = null

      app.config.globalProperties.$toast.open({
        message: 'You are successfully logged out',
        type: 'success',
        duration: 4000,
        dismissible: true
      })

      this.router.push('/login')
    }
  }
})
