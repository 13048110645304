import { defineStore } from 'pinia'
import { settingsStarCoin } from '@/helpers/requests'
import { get } from '@/helpers/fetch'

const filtersDefaultValues = {
  daterange: {
    from: null,
    to: null
  },
  market_dominance: {
    from: null,
    to: null
  }
}

export const useSocialActivityStore = defineStore({
  id: 'social-activity',
  state: () => ({
    limit: 20,
    items: [],
    loadingStatus: '', // loading, loading-more, loaded, loaded-all
    sort: {
      field: 'market_dominance',
      direction: 'desc' // desc - from big to small
    },
    searchString: '',
    starredCoinsOnly: false,
    exchangeCoinsOnly: false,
    filtersMinMaxValues: filtersDefaultValues,
    filtersCurrentValues: filtersDefaultValues,
    filters: filtersDefaultValues
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: [
          'sort',
          'starredCoinsOnly'
        ]
      }
    ]
  },
  getters: {
    sortString (state) {
      return state.sort.field + ':' + state.sort.direction
    }
  },
  actions: {
    toggleSortDirection (field) {
      if (!field) return

      // Single sort
      if (this.sort.field === field) {
        if (this.sort.direction === 'asc') {
          this.sort.direction = 'desc'
        } else {
          this.sort.direction = 'asc'
        }
      } else {
        this.sort.field = field
        this.sort.direction = 'desc'
      }
    },
    async starTicker ({ ticker, starred }) {
      const index = this.items.findIndex((item) => {
        return item.ticker === ticker
      })

      if (index > -1) {
        this.items[index].starred = !starred

        try {
          await settingsStarCoin({
            action: starred ? 'unstar' : 'star',
            ticker: ticker
          })

          // Remove item from "starred" filtered items
          if (starred && this.starredCoinsOnly) {
            this.items.splice(index, 1)
          }
        } catch (error) {
          this.items[index].starred = starred
        }
      }
    },
    async getSocialActivityData (type = '') {
      try {
        if (type === 'load_more') {
          this.loadingStatus = 'loading-more'
        } else {
          this.loadingStatus = 'loading'
        }

        let offset = 0

        if (type === 'sort' || type === 'first_load' || type === 'filter') {
          offset = 0
        } else {
          offset = this.items.length
        }

        const params = {
          search: this.searchString,
          starred: this.starredCoinsOnly,
          exchange_coins_only: this.exchangeCoinsOnly,
          offset: offset,
          limit: this.limit,
          sort: this.sortString,
          filters: JSON.stringify(this.filters)
        }

        const response = await get('/data/social-activity', params)

        if (response.items.length < this.limit || response.items.length === 0) {
          this.loadingStatus = 'loaded-all'
        } else {
          this.loadingStatus = 'loaded'
        }

        if (type === 'load_more') {
          this.items.push(...response.items)
        } else {
          this.items = response.items
        }

        this.filtersMinMaxValues = response.filters_min_max_values
        this.filtersCurrentValues = response.filters_current_values
      } catch (err) {
        console.log(err.message)
      }
    }
  }
})
