<template>
  <div
    :class="[sort.field === field ? 'visible' : 'invisible']"
    class="flex flex-col">
    <InlineSvg
      class="h-4 w-4 ml-1"
      :src="require(`@/assets/svg/sort/${sortIconName}.svg`)"/>
  </div>
</template>

<script>

export default {
  name: 'SocialActivitySortIcon',
  components: {},
  props: {
    field: {
      type: String,
      default: '',
      required: true
    },
    sort: {
      type: Object,
      default: () => ({
        field: '',
        direction: ''
      }),
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    sortIconName () {
      return this.sort.direction === 'desc'
        ? 'arrow-down-wide-short-secondary'
        : 'arrow-down-short-wide-secondary'
    }
  },
  mounted () {
  },
  methods: {}
}
</script>

