<template>
  <svg ref="chart"/>
</template>

<script>

import { scaleTime, scaleLinear } from 'd3-scale'
import { select } from 'd3-selection'
import { extent, min, max } from 'd3-array'
import { line, curveMonotoneX } from 'd3-shape'

export default {
  name: 'SocialActivitySmallChart',
  components: {},
  props: {
    tweets: {
      type: Array,
      default: () => [],
      required: true
    },
    prices: {
      type: Array,
      default: () => [],
      required: true
    },
    priceChange: {
      type: Number,
      default: () => null,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {},
  mounted () {
    const data = this.prices
    const data2 = this.tweets

    const width = 180
    const height = 50

    const el = this.$refs.chart

    const margin = {
      top: 5,
      bottom: 5,
      right: 5,
      left: 5
    }

    const svg = select(el)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)

    // Axes

    // Set the ranges
    const x = scaleTime().range([0, width])
    const y0 = scaleLinear().range([height, 0])
    const y1 = scaleLinear().range([height, 0])

    // Define the 1st line
    const valueLine = line()
      .x(function (d) { return x(d.time) })
      .y(function (d) { return y0(d.value) })
      .curve(curveMonotoneX)

    // Define the 2nd line
    const valueLine2 = line()
      .x(function (d) { return x(d.time) })
      .y(function (d) { return y1(d.value) })
      .curve(curveMonotoneX)

    // Scale the range of the data
    x.domain(extent(data, (item) => { return item.time }))

    y0.domain([min(data, (item) => { return +item.value }), max(data, (item) => { return +item.value })])
    y1.domain([min(data2, (item) => { return +item.value }), max(data2, (item) => { return +item.value })])

    // Add the valueLine path
    svg.append('path')
      .data([data])
      .attr('fill', 'none')
      .attr('class', 'line')
      .style('stroke', this.priceChange > 0 ? '#4fc280' : '#d7455b')
      .attr('stroke-width', 2)
      .attr('d', valueLine)

    // Add the valueLine2 path
    svg.append('path')
      .data([data2])
      .attr('fill', 'none')
      .attr('class', 'line')
      .attr('stroke', '#cfd6e4')
      .attr('stroke-width', 2)
      .attr('d', valueLine2)
  },
  methods: {}
}
</script>


<style>
  .d3-small-axis line {
    stroke: #e5e7eb;
  }

  .d3-small-axis path {
    stroke: #e5e7eb;
  }

  .d3-small-axis text {
    fill: #131722;
    font-size: 8px;
  }
</style>
