<template>
  <div class="flex flex-wrap border">
    <div class="py-2 px-3 border-r">
      <b>Exchange:</b> {{ metadata.exchange }}
    </div>

    <div class="py-2 px-3 border-r">
      <b>Timeframe:</b> {{ filters_current_values.timeframe }}
    </div>

    <div class="py-2 px-3">
      <b>Date range (UTC):</b> {{ formatUnixUTC(+filters_current_values.start_date) }} → {{ formatUnixUTC(+filters_current_values.finish_date) }}
    </div>
  </div>
</template>

<script>
import formatDate from '@/mixins/formatDate'
import { mapState } from 'pinia'
import { usePriceChangesStore } from '@/stores/price-changes'

export default {
  name: 'PriceChangesHeader',
  components: {},
  mixins: [formatDate],
  props: {},
  data () {
    return {}
  },
  computed: {
    ...mapState(usePriceChangesStore, [
      'metadata',
      'filters_current_values'
    ])
  },
  mounted () {
  },
  methods: {}
}
</script>

