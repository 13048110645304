<template>
  <div class="my-2">
    <DateRangeInput
      :name="'Date range (UTC)'"
      :date-range="{ from: filters_current_values.start_date, to: filters_current_values.finish_date }"
      @submit="applyDateRange"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { usePriceChangesStore } from '@/stores/price-changes'
import DateRangeInput from '@/components/Filters/DateRangeFilter.vue'

export default {
  name: 'PriceChangesFilters',
  components: {
    DateRangeInput
  },
  props: {},
  data () {
    return {}
  },
  computed: {
    ...mapState(usePriceChangesStore, [
      'filters_current_values'
    ])
  },
  mounted () {},
  methods: {
    ...mapActions(usePriceChangesStore, [
      'getPriceChanges'
    ]),
    applyDateRange (dateRange) {
      console.log(dateRange)

      // this.getPriceChanges()
    }
  }
}
</script>

