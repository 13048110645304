<template>
  <div class="h-screen flex items-center justify-center flex-grow">
    <LoginForm/>
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm'

export default {
  name: 'LoginView',
  components: {
    LoginForm
  },
  data () {
    return {}
  },
  computed: {},
  created () {
    document.title = 'CAT :: Login'
  },
  methods: {}
}
</script>

