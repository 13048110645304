<template>
  <div class="leading-3 whitespace-nowrap">
    <div>
      <span class="text-sm text-gray-900">UTC</span>
      <time class="text-sm text-gray-900 font-semibold ml-1">{{ currentTimeUTC }}</time>
    </div>

    <div>
      <span class="text-sm text-gray-900">{{ timezone }}</span>
      <time class="text-sm text-gray-900 font-semibold ml-1">{{ currentTime }}</time>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'TestView',
  components: {},
  props: {
    type: {
      type: String,
      default: 'main'
    }
  },
  data () {
    return {
      currentTime: '',
      currentTimeUTC: '',
      timezone: dayjs.tz.guess()
    }
  },
  computed: {},
  mounted () {
    this.getNow()
    setInterval(() => { this.getNow() }, 1000)
  },
  methods: {
    getNow () {
      this.currentTime = dayjs()
        .tz(this.timezone)
        .format('HH:mm')

      this.currentTimeUTC = dayjs()
        .utc()
        .format('HH:mm')
    }
  }
}
</script>

