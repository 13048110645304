<template>
  <div class="w-12 bg-gray-100 h-1 rounded-full">
    <div
      class="h-1 rounded-full"
      :class="[
        { 'bg-green-300': tweetsActivityPercent >= 50 },
        { 'bg-orange-300': tweetsActivityPercent >= 30 && tweetsActivityPercent < 50 },
        { 'bg-red-300': tweetsActivityPercent < 30 },
      ]"
      :style="{ width: tweetsActivityPercent + '%' }"/>
  </div>
</template>

<script>

export default {
  name: 'SocialActivityTweetsWeight',
  components: {},
  props: {
    coin: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {}
  },
  computed: {
    allActivitySum () {
      const coin = this.coin

      return coin.tweets_sum + coin.tweet_quotes_sum + coin.tweet_retweets_sum + coin.tweet_replies_sum +
        coin.tweet_favorites_sum
    },
    tweetsPercent () {
      return (this.coin.tweets_sum * 100) / this.allActivitySum
    },
    tweetsActivityPercent () {
      return ((this.allActivitySum - this.coin.tweets_sum) * 100) / this.allActivitySum
    }
  },
  mounted () {
  },
  methods: {}
}
</script>
