<template>
  <div class="w-12 bg-gray-100 h-1 rounded-full">
    <div
      class="h-1 rounded-full"
      :class="[
        { 'bg-green-300': redditPostsActivityPercent >= 50 },
        { 'bg-orange-300': redditPostsActivityPercent >= 30 && redditPostsActivityPercent < 50 },
        { 'bg-red-300': redditPostsActivityPercent < 30 },
      ]"
      :style="{ width: redditPostsActivityPercent + '%' }"/>
  </div>
</template>

<script>

export default {
  name: 'SocialActivityRedditWeight',
  components: {},
  props: {
    coin: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {}
  },
  computed: {
    allActivitySum () {
      const coin = this.coin

      return coin.reddit_posts_sum + coin.reddit_comments_sum
    },
    redditPostsActivityPercent () {
      return ((this.allActivitySum - this.coin.reddit_posts_sum) * 100) / this.allActivitySum
    }
  },
  mounted () {
  },
  methods: {}
}
</script>
