<template>
  <div>
    <CoinCardDescription :coin="coin"/>

    <CoinCardChart
      :social-volume="socialVolumeItems"
      :prices="priceItems"/>
  </div>
</template>

<script>
import CoinCardChart from '@/components/CoinCard/Includes/CoinCardChart'
import CoinCardDescription from '@/components/CoinCard/Includes/CoinCardDescription'

export default {
  name: 'CoinCardBody',
  components: {
    CoinCardChart,
    CoinCardDescription
  },
  props: {
    coin: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    priceItems () {
      return this.coin.data.map(item => {
        return {
          value: item.close,
          time: item.time * 1000
        }
      })
    },
    socialVolumeItems () {
      return this.coin.data.map(item => {
        return {
          value: item.social_volume,
          time: item.time * 1000
        }
      })
    }
    // links () {
    //   return pickBy(this.coin.metadata, (value, key) => {
    //     return key.includes('link') && !key.includes('links')
    //   })
    // }
  },
  mounted () {
  },
  methods: {}
}
</script>

