import { useAuthStore } from '@/stores/auth'

const httpPath = 'https://api.cryptoat.org'

// Implement error handling when using the Fetch API
// https://web.dev/fetch-api-error-handling/

const fetchApi = async (url, params, method) => {
  const auth = useAuthStore()
  const headers = new Headers()

  let baseUrl
  let response

  if (url.includes('http') || url.includes('.netlify')) {
    baseUrl = url
  } else {
    baseUrl = httpPath + url
  }

  if (method === 'GET') {
    baseUrl = params ? `${baseUrl}?${new URLSearchParams(params)}` : baseUrl
  } else {
    headers.append('Content-type', 'application/json; charset=UTF-8')
  }

  if (auth.token) {
    headers.append('x-access-token', auth.token)
  }

  const options = {
    method: method,
    headers: headers
  }

  if (method !== 'GET' && method !== 'HEAD') {
    if (params) {
      options.body = JSON.stringify(params)
    }
  }

  try {
    response = await fetch(baseUrl, options)
  } catch (error) {
    console.log('There was an error', error)

    throw new Error(error)
  }

  if (response?.ok) {
    return response.json()
  } else {
    console.log(`HTTP Response Code: ${response?.status}`)

    if (response?.status === 401) {
      auth.logout()
    }

    // return Promise.reject(response.statusText)
    throw new Error(response?.statusText)
  }
}

const get = async (url, params) => {
  return fetchApi(url, params, 'GET')
}

const post = async (url, body) => {
  return fetchApi(url, body, 'POST')
}

const put = async (url, body) => {
  return fetchApi(url, body, 'PUT')
}

const del = async (url, body) => {
  return fetchApi(url, body, 'DELETE')
}

export {
  get,
  post,
  put,
  del
}
