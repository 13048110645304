<template>
  <div class="mx-auto mt-8 max-w-6xl px-6 mb-8">
    <h1 class="mb-8">
      Dashboard
    </h1>

    <div
      v-show="!loaded"
      class="px-4 py-20 flex items-center justify-center">
      <span class="w-16 h-16 loader"/>
    </div>

    <template v-if="loaded">
      <div class="flex flex-wrap -mx-2">
        <div class="mb-6 px-2 w-full lg:w-6/12">
          <DashboardNews v-model:data="news"/>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { get } from '@/helpers/fetch'
import DashboardNews from '@/components/Dashboard/DashboardNews.vue'

export default {
  name: 'HomeView',
  components: {
    DashboardNews
  },
  data () {
    return {
      loaded: false,
      news: {
        value: null,
        error: null
      }
    }
  },
  computed: {},
  created () {
    document.title = 'CAT :: Home'
  },
  async mounted () {
    try {
      await Promise.allSettled([
        this.getData('/dashboard/news', 'news', { limit: 6, filter: 'rising' })
      ])
    } finally {
      this.loaded = true
    }
  },
  methods: {
    getData (route, name, params) {
      return get(route, params)
        .then((data) => {
          this[name].value = data
        })
        .catch((error) => {
          this[name].error = error?.message ? error.message : 'Data fetch error'
        })
    }
  }
}
</script>

