<template>
  <div>
    <p
      v-if="coin.metadata.short_summary"
      :class="[textExpanded ? 'mb-2' : 'mb-10']"
      class="text-lg text-slate-800">
      {{ coin.metadata.short_summary }}

      <a
        v-if="!textExpanded"
        class="text-sky-700 cursor-pointer"
        @click="textExpanded = true">
        Read&nbsp;more
      </a>
    </p>

    <p
      v-if="coin.metadata.description && textExpanded"
      class="mb-10 text-lg text-slate-800">
      {{ coin.metadata.description }}
    </p>
  </div>
</template>

<script>

export default {
  name: 'CoinCardDescription',
  components: {},
  props: {
    coin: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data () {
    return {
      textExpanded: false
    }
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

