<template>
  <VlTooltip
    v-if="sumSentiments"
    align="center"
    placement="top"
    :gap="6"
    :width="150">
    <svg ref="chart"/>

    <template #content>
      <div
        v-for="item in filteredSentiments"
        v-show="item.value > 0"
        :key="item.name"
        class="flex items-center">
        <div
          :style="{ background: item.color }"
          class="w-2 h-2 rounded-full mr-1"/>

        {{ item.name }}

        <span class="text-xs font-bold ml-1">{{ item.percent }}%</span>
      </div>
    </template>
  </VlTooltip>
</template>

<script>

import VlTooltip from '@/components/Functional/VlTooltip.vue'
import { sum } from 'd3-array'
import { select } from 'd3-selection'
import { arc, pie } from 'd3-shape'

export default {
  name: 'SocialActivitySentimentPie',
  components: {
    VlTooltip
  },
  props: {
    sentiments: {
      type: Array,
      default: () => [],
      required: true
    },
    coin: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    sentimentsWithPercents () {
      const sentimentsSum = sum(this.sentiments, item => item.value)

      return this.sentiments.map(item => {
        const percent = (item.value * 100) / sentimentsSum

        return {
          ...item,
          percent: parseFloat(percent.toFixed(2))
        }
      })
    },
    filteredSentiments () {
      return this.sentimentsWithPercents.filter(item => item.percent > 1)
    },
    sumSentiments () {
      return sum(this.filteredSentiments, item => item.value)
    }
  },
  mounted () {
    const data = this.filteredSentiments

    const width = 32
    const height = 32

    const el = this.$refs.chart

    const svg = select(el)
      .attr('width', width)
      .attr('height', height)

    const pieData = pie()
      .value((d) => d.value)
      .sort((a, b) => a.value - b.value)(data)

    const arcPie = arc()
      .innerRadius(0)
      .outerRadius(16)

    const arcGroup = svg
      .append('g')
      .attr('transform', 'translate(16, 16)')
      .selectAll('path')
      .data(pieData)

    arcGroup.join('path')
      .attr('fill', (d) => d.data.color)
      .attr('d', arcPie)
  },
  methods: {}
}
</script>
